<template>
  <div id="app" ref="app">
    <div ref="content" class="content">
      <b-row no-gutters h-align="center" class="d-flex justify-content-between">
        <b-col class="d-flex justify-content-center">
              <b-form-group class="m-0 mt-1">
                <!-- <div class="text-center">
                  How: 
                </div> -->
                <b-form-radio-group
                  buttons
                  stacked
                  button-variant="outline-primary"
                  size="sm"
                  id="method"
                  v-model="includeForms"
                  class="under-trick m-0"
                >
                  <b-form-radio class="" value="false">Exact</b-form-radio>
                  <b-form-radio class="" value="true">All Forms</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
        </b-col>
        <b-col class="d-flex justify-content-center">
              <b-form-group class="m-0 mt-1">
                <!-- <div class="text-center">
                  Where:
                </div> -->
                <b-form-radio-group
                  buttons
                  stacked
                  button-variant="outline-primary"
                  size="sm"
                  id="method"
                  v-model="includeSentences"
                  class="under-trick m-0"
                >
                  <b-form-radio class="" value="false">Phrase</b-form-radio>
                  <b-form-radio class="" value="true">Phrase & <br>Sentence</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
        </b-col>
        <!-- <b-col class="d-flex justify-content-center"> -->
        <b-col no-gutters>
          <div>
            <b-form-group class="m-0">
              <div class="w-100 text-center">
                <small>Words to Search: <b>{{matchCount}}</b></small> 
              </div>
              <div class="d-flex justify-content-center">
                <b-form-input class="w-75" id="match" v-model="matchCount" type="range" min="1" max="3"></b-form-input>
              </div>
            </b-form-group>
          </div>
          <div>
            <!-- <b-col class="d-flex justify-content-center"> -->
            <b-col no-gutters class="w-100 text-center m-0 p-0">
              <div class="cursor-pointer">
                <b-form-group class="m-0 pb-0 cursor-pointer">
                  <label class="mb-0 cursor-pointer" for="rank">Show Rank&nbsp;</label>
                  <b-form-checkbox v-model="showRank" id="rank" class="d-inline m-0 pt-1 cursor-pointer" inline></b-form-checkbox>
                </b-form-group>
              </div>
              <div class="cursor-pointer">
                <b-form-group class="m-0 pt-0 cursor-pointer">
                  <label class="mt-0 cursor-pointer" for="expand">Expand All&nbsp;</label>
                  <b-form-checkbox v-model="expandAll" id="expand" class="d-inline m-0 pt-1 cursor-pointer" inline></b-form-checkbox>
                </b-form-group>
              </div>
            </b-col>
          </div>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          ~{{ debug }}~
        </b-col>
      </b-row>

      <b-row no-gutters h-align="center">
        <b-col class="d-flex justify-content-center">
          <span>Search Query:&nbsp;</span><b>{{ needle }}</b>
            Results: <i>{{ results.length }}</i>
        </b-col>
      </b-row> -->

      <!-- <b-row no-gutters h-align="center"> -->
      <!-- </b-row> -->

      <b-row class="border-top" no-gutters>
        <b-col class="p-1">
          <div v-if="clampedResults.length < 1">
            <div id="no_results">
              No Results
            </div>
          </div>
          <div v-else v-for="res in clampedResults" v-bind:key="res">
            <div class="block">
              <div class="line">
                <span v-if="showRank" class="rank">#{{ lines[res][0] }}</span>
                {{ lines[res][4] }}
              </div>
              <div class="example" :class="expandAll ? 'show_block' : ''">
                {{ lines[res][5] }}
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style>
  * {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .block {
    padding-bottom: 0.25em;
    padding-right: 1.5em;
  }
  .debug * {
    border: 1px solid red !important;
  }
  label {
    font-size: 14px;
  }
  .cursor-pointer {
    cursor: pointer !important;
  }
  .line {
    /* cursor: pointer; */
    /* font-size: 110%; */
    /* padding-left: 1em;
    padding-right: 1em; */
  }
  /* .line:hover {
    font-weight: bold;
  } */
  .rank {
    font-size: small;
    font-weight: bold;
  }
  .example {
    border-top: 1pt solid #333;
    font-size: 100%;
    font-style: italic;
    border-bottom: 1pt solid #333;
    display:none;
  }
  .show_block {
    display: block !important;
  }
  .block:hover {
    background: #fafafa;
  }
  .block:hover .example {
    display: block;
  }
  .block:hover .line {
    /* font-weight: bold; */
  }
  #no_results {
    color: #aaa;
    font-size: 150%;
    text-align: center;
    margin-top: 1em;
  }

  .under-trick .active {
    text-decoration: underline;
    font-weight: bold;
  }
</style>

<script>
import ac from '@/ac.json'
import piv from '@/pivots.json'
/* eslint-disable no-unused-vars */

const RANK = 0
const OCCURS = 1
const PIVOT = 2
const COLLOCATE = 3
const PHRASE = 4
const SENTENCE = 5

  export default {
    name: 'App',
    data() {
      return {
        includeSentences: 'true',
        includeForms: 'true',

        // searchMethod: 'all',
        showRank: true,
        expandAll: true,
        matchCount: Number(1),
        debug: '',
        message: '',
        lines: ac,
        lc_lines: [],
        pivots: piv,
        needle: '',
        cursor: {},
        maxVisible: 10,
        start: Number(),
        end: Number(),
        results: Array(),
        old_text: '',
        intv_cursor: Number(),
        debounce_load: 0,
        activeHover: Number(),
      }
    },
    mounted() {
      this.lc_lines = this.lines.map(l => {
        l[SENTENCE] = l[SENTENCE].toLowerCase()
        l[PHRASE] = l[PHRASE].toLowerCase()
        return l
      })
      window.addEventListener('scroll', this.loadMore)
      this.intv_cursor = setInterval(() => {
        window.Word.run(async (context) => {
          // console.log('checking' + new Date().toDateString() )
          // must call isEmpty to check if it's a cursor
          let cursor = context.document.getSelection()
          context.load(cursor)
          await context.sync()
          context.load(cursor.paragraphs)
          await context.sync()

          if (cursor.isEmpty) {
            if (cursor.paragraphs) {
              if (cursor.paragraphs.items) {
                if (cursor.paragraphs.items[0]) {
                  if (cursor.paragraphs.items[0].text) {
                    if (this.old_text !== cursor.paragraphs.items[0].text) {
                      this.cursor = cursor.paragraphs.items[0]
                      this.old_text = cursor.paragraphs.items[0].text
                      this.search(cursor.paragraphs.items[0].text)
                    }
                  }
                }
              }
            }
          }
          else {
            // it's a selection
            this.needle = ''
            this.results = []
          }
        })
      }, 400)
    },
    destroyed() {
      window.removeEventListener('scroll', this.loadMore);
      clearInterval(this.intv_cursor)
    },
    computed: {
      // showBlock() {
      //   if (this.expandAll) {
      //     return {
      //       'display': 'block'
      //     }
      //   }
      //   else {
      //     return {
      //       'display': 'none'
      //     }
      //   }
      // },
      clampedResults() {
        return this.results.slice(0, this.maxVisible)
      }
    },
    methods: {
      loadMore() {
        clearTimeout(this.debounce_load)
        this.debounce_load = setTimeout(() => {
          // there is a very small bug here where it will auto-set to 20 because this condition is always true when the height is lower than 100vh
          if (parseInt(document.documentElement.scrollHeight - window.scrollY) <= parseInt(document.documentElement.clientHeight)) {
            this.maxVisible = this.maxVisible + 10
          }
        }, 50)
      },
      search(cursor) {
        // eventually we should debounce this I guess, or debounce the watchers
        this.start = performance.now()

        // reset clamp count
        this.maxVisible = 30

        // this.debug = cursor

          // 160 is the nbsp space
        const line = cursor.replace(RegExp(String.fromCharCode(160), "g"), ' ')
        const lastChar = line.charCodeAt(line.length - 1)
        // console.warn([...Array(line.length).keys()].map(i => line.charCodeAt(i)))

        // if (line !== ' ' && lastChar == 32) {
        if (line !== ' ' && lastChar == 32) {
          // console.log(lastChar, line.length, line, line.indexOf(' '))
          // check if we are at the start of the document
          if (line.indexOf(' ') + 1 === line.length) {
            this.needle = line.toLowerCase()
          }
          else {
            let offset = [...Array(parseInt(this.matchCount))].reduce((a,_) => line.lastIndexOf(' ', a - 1), line.length - 2)
            this.needle = line.substr(offset + 1, line.length).toLowerCase()
          }

          // find matching root
          for (let pivot of Object.keys(this.pivots)) {
            if (this.pivots[pivot].includes(this.needle.trim())) {
              this.needle = pivot + ' '
            }
          }

          let pivots = [this.needle]
          let index = this.needle.indexOf(' ')

          if (this.includeForms === 'true') {
            if (index > 0 && index < (this.needle.length-1)) {
              pivots = [
                this.needle,
                ...this.needle.split(' ').map(w => w.trim()),
                ...this.needle.split(' ').map(word => {
                  if (this.pivots[word.trim()]) {
                    return this.pivots[word.trim()].map(v => v+' ')
                  }
                  else {
                    return ''
                  }
                })
              ].filter(word => word).flat()
            }
            else {
              if (this.pivots[this.needle.trim()]) {
                pivots = [
                  this.needle,
                  this.pivots[this.needle.trim()].map(v => v+' ')
                ].flat()
              }
            }
          }

          let temp_results = []

          if (this.includeSentences === 'true') {
            for (let line in this.lines) {
              for (let pivot of pivots) {
                if (this.lc_lines[line][SENTENCE].indexOf(pivot) !== -1 || this.lc_lines[line][PHRASE].indexOf(pivot) !== -1) {
                  temp_results.push(line)
                  break
                }
              }
            }
          }
          else {
            for (let line in this.lines) {
              for (let pivot of pivots) {
                if (this.lc_lines[line][PHRASE].indexOf(pivot) !== -1) {
                  temp_results.push(line)
                  break
                }
              }
            }
          }

          // this.debug = this.needle

          this.results = temp_results
          this.end = performance.now()
        }
        else {
          // weird line composition
          this.needle = ''
          this.results = []
        }
      },
      insertText() {
        // window.Word.run(async (context) => {
        //   // replace, start, end
        //   const r = context.document.body.insertText(text.substr(this.needle.length) + ' ', "end")
        //   return context.sync().then(function() {
        //     r.getRange("End").select()
        //   })
        // });
      },
      // testSearch() {
      //   // window.Word.run(async (context) => {
      //   //   // must call isEmpty to check if it's a cursor
      //   //   // don't forget to preserve the capitalisation if it's the first
      //   //   // sanity checks:
      //   //   // last char is not .
      //   //   // last char is <space>
      //   //   // there is at least one word before <space>
      // },
    },
    watch: {
      matchCount() {
        this.search(this.old_text)
      },
      includeSentences() {
        this.search(this.old_text)
      },
      includeForms() {
        this.search(this.old_text)
      },
    },
  };
</script>

